import { MagicWand } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import {
  Control,
  UseFormRegister,
  UseFormTrigger,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { useEntitiesQuery } from "../../../api/rest/entities";
import { Alert } from "../../../components/design-system/Alert";
import { Button } from "../../../components/design-system/Button";
import { Description } from "../../../components/design-system/Description";
import { Dialog } from "../../../components/design-system/Dialog";
import { EntityItem } from "../../../components/design-system/EntityItem";
import {
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import {
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "../../../components/design-system/icons";
import { SelectEntity } from "../../../components/design-system/SelectEntity";
import { TableV2 } from "../../../components/design-system/TableV2";
import { AddEntity } from "../../../components/Entities/AddEntity";
import { useCurrentCompany } from "../../../context/account";
import { Entity } from "../../../types/models/entities";
import {
  ShareblockWithEntity,
  ShareholderBlocks,
  SharesTransferForm,
  TransferParticipants,
} from "../../../types/models/shares";
import {
  calcSumWithinRange,
  distributeBlocksProrata,
} from "../../../utils/shares";
import { ProRataConfirmation } from "../IssueShares/ProRataConfirmation";
import { TransferErrors, TransferForm } from "./TransferForm";

type TransferDialogProps = MultiTransferProps & {
  id: string;
  onClose: () => void;
};

const TransferDialog = (props: TransferDialogProps) => {
  const [initialValue, setInitialValue] = useState("");
  const [id, setId] = useState(props.id);
  const i18n = useTranslation();
  const company = useCurrentCompany();
  const entitiesQuery = useEntitiesQuery(company?.orgNumber);
  const entities = entitiesQuery.data || [];

  const initialValueRef = useRef<TransferParticipants | null>(null);

  useEffect(() => {
    initialValueRef.current = props.value;
  }, []);

  useEffect(() => {
    setInitialValue(JSON.stringify(props.value));
  }, []);

  const list: Omit<Entity, "contact">[] =
    props.type === "recipient"
      ? entities.filter((e) => e.id !== props.sender)
      : Array.from(
          new Set(
            props.shareBlocks
              .filter(({ entity }) => entity.id !== props.recipient)
              .map((s) => s.entity)
          )
        );

  const onClose = () => {
    props.onChange(JSON.parse(initialValue));
    props.onClose();
  };

  const onSave = async () => {
    const successful = await props.trigger(`transfers.${props.id}`);
    if (successful) {
      props.onClose();
    }
  };

  return (
    <Dialog
      title={i18n.t("events.transfer.transfers.title")}
      isOpen
      size="md"
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>{i18n.t("label.cancel")}</Button>
          <Button
            variant="solid"
            color="primary"
            onClick={onSave}
            disabled={!id}
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <div className="tw-space-y-6">
        <FormGroup>
          <div className="tw-flex tw-items-center tw-justify-between">
            <FormLabel htmlFor="targetId">
              {i18n.t(
                props.type === "recipient" ? "label.recipient" : "label.sender"
              )}
            </FormLabel>
            {props.type === "recipient" && (
              <AddEntity
                currentCompany={company!}
                onSuccess={(newEntity) => {
                  entitiesQuery.refetch();
                  setId(newEntity.id);
                }}
              />
            )}
          </div>
          <SelectEntity
            id="targetId"
            options={list}
            value={id}
            onChange={(value) => {
              if (typeof value === "string" || value === null) {
                if (id) {
                  // Clear updates for the current target when changing target
                  const original = JSON.parse(initialValue)[id];
                  if (original) {
                    props.onChange({ ...props.value, [id]: original });
                  } else {
                    const { [id]: _, ...rest } = props.value;
                    props.onChange(rest);
                  }
                }
                setId(value || "");
              }
            }}
            shareBlocks={props.shareHolders}
            menuPosition="fixed"
            isClearable
          />
        </FormGroup>
        {id && (
          <TransferForm
            {...props}
            value={props.value[id]?.transfers || []}
            method={props.value[id]?.type ?? "basic"}
            onChange={(data) =>
              props.onChange({
                ...props.value,
                [id]: { ...props.value[id], transfers: data },
              })
            }
            id={id}
            sender={props.type === "sender" ? id : props.sender}
          />
        )}
      </div>
    </Dialog>
  );
};

type MultiTransferProps = {
  value: TransferParticipants;
  onChange: (value: TransferParticipants) => void;
  type: "sender" | "recipient";
  sender?: string;
  recipient?: string;
  entities: Entity[];
  shareHolders: ShareholderBlocks[];
  shareBlocks: ShareblockWithEntity[];
  register: UseFormRegister<SharesTransferForm>;
  trigger: UseFormTrigger<SharesTransferForm>;
  control: Control<SharesTransferForm>;
  errors?: TransferErrors;
};

const MultiTransfer = (props: MultiTransferProps) => {
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const transfers = useWatch({
    control: props.control,
    name: "transfers",
  });
  const [showProRataConfirm, setShowProRataConfirm] = useState(false);
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});
  const [showDialog, setShowDialog] = useState<string | undefined>();
  const entitiesMap = Object.fromEntries(props.entities.map((e) => [e.id, e]));

  const proRata = () => {
    const matchingBlocks = props.shareBlocks.filter(
      (b) => !b.cancelled && b.holder.id === props.sender
    );
    const otherShareholders = props.shareHolders.filter(
      (s) => s.id !== props.sender
    );
    const distribution = distributeBlocksProrata(
      matchingBlocks,
      otherShareholders
    );
    const resultingTransfers = otherShareholders.reduce((prev, curr) => {
      const matchingDistribution = distribution.filter(
        (d) => d.holderId === curr.id
      );
      if (matchingDistribution.length > 0) {
        return {
          ...prev,
          [curr.id]: {
            type: "basic" as "basic" | "range",
            transfers: matchingDistribution.map((d) => ({
              shareType: d.type,
              sharesAmount: d.total,
              shareRanges: [],
            })),
          },
        };
      }
      return prev;
    }, {} as TransferParticipants);
    props.onChange(resultingTransfers);
  };

  const matchingBlocks = props.shareBlocks.filter(
    (b) => !b.cancelled && b.holder.id === props.sender
  );
  const shareholderTotals = matchingBlocks.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.type]: (prev[curr.type] || 0) + calcSumWithinRange(curr),
    }),
    {} as Record<string, number>
  );

  const flatTransfers = Object.values(transfers).flatMap((i) => i.transfers);
  const allocatedTotals = flatTransfers.reduce((prev, curr) => {
    const total =
      curr.shareRanges.length > 0
        ? curr.shareRanges.reduce(
            (rangesTotal, range) => rangesTotal + range.end! - range.start! + 1,
            0
          )
        : curr.sharesAmount || 0;
    return {
      ...prev,
      [curr.shareType!]: (prev[curr.shareType!] || 0) + total,
    };
  }, {} as Record<string, number>);

  const leftover = Object.entries(shareholderTotals).reduce(
    (prev, [type, total]) => ({
      ...prev,
      [type]: total - (allocatedTotals[type] || 0),
    }),
    {} as Record<string, number>
  );

  const rows = Object.entries(props.value).map(([id, data]) => ({
    key: id,
    target: (
      <EntityItem value={entitiesMap[id]!} hasFlag={!isTabletOrMobileDevice} />
    ),
    shares: (
      <div className="tw-flex tw-flex-col">
        {(data.transfers || []).map((t) =>
          t.sharesAmount ? (
            <div className="tw-text-sm tw-font-medium" key={t.shareType}>
              {t.sharesAmount} {t.shareType}
            </div>
          ) : (
            <div className="tw-mb-4" key={t.shareType}>
              {t.shareRanges.map((r) => {
                const block = props.shareBlocks.find(
                  (b) => b.start === r.block.start && b.end === r.block.end
                );
                return (
                  <Description
                    key={block?.type}
                    title={`${r.start} - ${r.end}`}
                    titleWeight="medium"
                    description={`${r.end! - r.start! + 1} ${block?.type}`}
                  />
                );
              })}
            </div>
          )
        )}
      </div>
    ),
    actions: (
      <div className="tw-flex tw-justify-end tw-gap-4">
        <button type="button" onClick={() => setShowDialog(id)}>
          <PencilIcon />
        </button>
        <button
          type="button"
          onClick={() => {
            const { [id]: _, ...rest } = props.value;
            props.onChange(rest);
          }}
        >
          <TrashIcon />
        </button>
      </div>
    ),
  }));

  return (
    <div className="tw-space-y-6 tw-rounded tw-border tw-p-4">
      <div className="tw-flex tw-justify-between">
        <h2 className="tw-text-xl tw-font-medium">
          {i18n.t(
            props.type === "recipient"
              ? "events.transfer.transfers.oneToMany"
              : "events.transfer.transfers.manyToOne"
          )}
        </h2>
        {props.type === "recipient" && (
          <Button
            onClick={() => {
              if (Object.keys(props.value).length > 0) {
                setShowProRataConfirm(true);
              } else {
                proRata();
              }
            }}
            disabled={!props.sender}
            prefix={<MagicWand />}
          >
            {i18n.t("events.transfer.transfers.autofill")}
          </Button>
        )}
      </div>
      {Object.keys(leftover).length > 0 && (
        <div className="tw-space-y-2">
          {Object.entries(leftover).map(([type, total]) =>
            total === 0 ? (
              <Alert type="success" key={type}>
                {i18n.t("events.transfer.transfers.allocated", { type })}
              </Alert>
            ) : total < 0 ? (
              <Alert type="error" key={type}>
                {i18n.t("events.transfer.transfers.exceeded", {
                  total: -total,
                  type,
                })}
              </Alert>
            ) : (
              <Alert type="neutral" key={type}>
                {i18n.t("events.transfer.transfers.leftover", { total, type })}
              </Alert>
            )
          )}
        </div>
      )}
      <TableV2
        columns={[
          {
            name: "target",
            title: i18n.t("events.transfer.transfers.column.target"),
            key: true,
            sortable: false,
          },
          {
            name: "shares",
            title: i18n.t("events.transfer.transfers.column.shares"),
            key: true,
            sortable: false,
          },
          {
            name: "actions",
            title: "",
            sortable: false,
            key: !isTabletOrMobileDevice,
          },
        ]}
        expandedRows={expanded}
        setExpandedRows={setExpanded}
        data={[
          ...rows,
          ...(rows.length > 0
            ? [
                {
                  key: "summary",
                  shares: (
                    <div className="tw-flex tw-flex-col">
                      {Object.entries(allocatedTotals).map(([type, total]) => (
                        <div
                          className="tw-text-sm tw-font-medium tw-text-secondary"
                          key={type}
                        >
                          {total} {type}
                        </div>
                      ))}
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
      <Button
        prefix={<PlusIcon />}
        onClick={() => {
          setShowDialog("");
        }}
        className="tw-w-full"
        disabled={props.type === "recipient" && !props.sender}
      >
        {i18n.t("events.transfer.transfers.add")}
      </Button>
      {showDialog !== undefined && (
        <TransferDialog
          {...props}
          id={showDialog}
          onClose={() => setShowDialog(undefined)}
        />
      )}
      {showProRataConfirm && (
        <ProRataConfirmation
          onClose={() => setShowProRataConfirm(false)}
          onConfirm={() => {
            proRata();
            setShowProRataConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export { MultiTransfer };
